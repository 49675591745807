import React  from 'react';
import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAdMXozCuZhIbj6jNUb7PgwgWQwkbsRr3o",
  authDomain: "rubianutri-40927.firebaseapp.com",
  projectId: "rubianutri-40927",
  storageBucket: "rubianutri-40927.appspot.com",
  messagingSenderId: "502828636342",
  appId: "1:502828636342:web:a178d9e5a23772ed90ae17",
  measurementId: "G-72DWQ463JQ"
};

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'HomePageView');

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Celin Bolado esteve aqui.
        </p>
        <img src='https://ih1.redbubble.net/image.240510348.2745/st,small,507x507-pad,600x600,f8f8f8.u4.jpg' alt="yeah" />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
